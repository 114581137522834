import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "truefortune",
  casinoId: 256,
  GA_TRACKING: "G-FR6D5TG80E",

  rivalChatGroupName: "True Fortune",
  prettyName: "True Fortune",
  contactEmail: "support@true-fortune.com",
  docsEmail: "documents@true-fortune.email",

  publicPromotions: [
    {id: 'TRUEFORTUNE_1', name: "200", state: "eligible", details: "welcome", imgPath: "assets/brands/truefortune/promo.png"},
    {id: 'TRUEFORTUNE_2', name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/truefortune/promo.png"},
    {id: 'TRUEFORTUNE_3', name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/truefortune/promo.png"},
  ],

  //SEO
  metaDescription: "Online Casino with Real Fortune at True Fortune Casino! Play fair-chance slot games fast, get no deposit bonuses, welcome bonus and free spins. Earn real cash in true fortune online casino and get secured transactions with safe payouts even in crypto now!",
  logoName: "true-fortune-online-casino-logo.png",
  logoAltText: "The official logo of True Fortune Casino looks sleek and modern, given that it uses two main colors: blue and white. It is evident that this online casino is trusted with its great variety in slots, table games, and live dealer experience. True Fortune Casino brings you online gaming in safety and rewards."


};


